import React, { useEffect } from "react";
import {
  Layout,
  Nav,
  TopBanner,
  VisionHome,
  PageBg,
  InsertBanner,
  AboutHome,
  ClinicsHome,
  NewsHome,
  CareerParticle,
  ContactParticle,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const IndexPage = () => {
  useEffect(() => {});
  return (
    <Layout>
      <Seo
        title="お客様に寄り添った医療サービスを"
        description="‶お客様に寄り添った医療サービスを - Be With Customers″をグループビジョンに掲げ、在宅医療や美容皮膚科を通じて安心して自分らしく過ごせる社会を実現させていく医療法人社団慶育会のコーポレートサイトです。グレースホームケアクリニックでは東京・神奈川を中心に訪問診療並びに在宅医療サービスを提供していきます。恵比寿美容皮膚科では最先端技術を用いた先進美容医療を提供していきます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,恵比寿美容皮膚科,美容皮膚科,お客様に寄り添った医療サービスを,Be With Customers"
      />
      <PageBg />
      <TopBanner />
      <Nav />
      <VisionHome />
      <InsertBanner />
      <AboutHome />
      <ClinicsHome />
      <CareerParticle />
      <NewsHome />
      <ContactParticle />
    </Layout>
  );
};

export default IndexPage;
